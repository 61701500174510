var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticStyle:{"padding":"0"},attrs:{"id":"welcome"}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-layout',{staticStyle:{"margin-top":"10%"},attrs:{"row":"","wrap":"","justify-center":"","align-center":""}},[_c('v-flex',{style:(_vm.mdUp ? 'text-align: left;' : 'text-align: center;'),attrs:{"xs12":"","sm12":""}},[_c('span',{style:(_vm.mdUp
              ? 'color:#772336;letter-spacing: 3.2px;margin-left:8%;'
              : 'color:#772336;letter-spacing: 3.2px;')},[_vm._v(" 02 "),_c('div',{staticClass:"line"}),_vm._v(" STC")]),_c('br'),_c('br'),_c('br'),_c('h1',{style:(_vm.mdUp
              ? 'font-size:60px;margin-left:8%;margin-right:2%;'
              : 'font-size:30px;')},[_vm._v(" Confinamiento ")]),_c('br'),_c('br'),_c('h2',{style:(_vm.mdUp
              ? 'font-size:20px;margin-left:8%;margin-right:45%'
              : 'font-size:20px;margin-left:2%;margin-right:2%;')},[_vm._v(" Nuestro sitio de disposición final de residuos de manejo especial cuenta con gran capacidad para el confinamiento, ubicado estratégicamente para brindar un excelente servicio como mejor opción para la industria. ")])])],1)],1),_c('v-container',{staticClass:"pa-4 text-center"},[_c('v-row',{staticClass:"fill-height",attrs:{"align":"center","justify":"center"}},[_vm._l((_vm.items),function(item,i){return [_c('v-col',{key:i,attrs:{"cols":"12","xs":"6","md":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var hover = ref.hover;
return [_c('v-card',{class:{ 'on-hover': hover },attrs:{"elevation":hover ? 12 : 2}},[_c('v-img',{attrs:{"src":item.img,"height":"550px"}},[_c('v-expand-transition',[(hover)?_c('div',{staticClass:" ",style:(item.pos == 0
                        ? 'height: 100%;color: white;text-align: center;font-size: 30px !important;background-color: #772336d1;padding: 75px 20px 0 20px;'
                        : 'height: 100%;color: white;text-align: center;font-size: 30px !important;background-color: #772336d1;padding: 125px 20px 0 20px;')},[_c('span',{style:(_vm.mdUp
                          ? 'top: 45%;position:absolute;left:10%;right:10%;'
                          : 'top: 45%;position:absolute;left:10%;right:10%;')},[_vm._v(" "+_vm._s(item.title)+" ")])]):_vm._e()])],1)],1)]}}],null,true)})],1)]})],2)],1),_c('v-container',{attrs:{"fluid":""}},[_c('v-layout',{staticStyle:{"margin-top":"0%"},attrs:{"row":"","wrap":"","justify-center":"","align-center":""}},[_c('v-flex',{style:(_vm.mdUp ? 'text-align: left;' : 'text-align: center;'),attrs:{"xs12":"","sm6":""}},[_c('h1',{style:(_vm.mdUp
              ? 'font-size:40px;margin-left:8%;margin-right:2%;'
              : 'font-size:30px;margin-left:2%;margin-right:2%;')},[_vm._v(" Contamos con permisos para todas nuestras operaciones ")]),_c('br'),_c('br'),_c('h2',{style:(_vm.mdUp
              ? 'font-size:20px;margin-left:8%;margin-right:2%'
              : 'font-size:20px;margin-left:2%;margin-right:2%;')},[_c('br'),_c('br'),_c('span',{staticStyle:{"color":"#772336"}},[_vm._v("•")]),_vm._v(" Transporte de residuos peligrosos, SEMARNAT, ASEA "),_c('br'),_c('span',{staticStyle:{"color":"#772336"}},[_vm._v("•")]),_vm._v(" Transporte de residuos de manejo especial, SEMA COAHUILA, ASEA "),_c('br'),_c('span',{staticStyle:{"color":"#772336"}},[_vm._v("•")]),_vm._v(" Almacenamiento y/o Acopio SEMA "),_c('br'),_c('span',{staticStyle:{"color":"#772336"}},[_vm._v("•")]),_vm._v(" Tratamiento de residuos de manejo especial SEMA "),_c('br'),_c('span',{staticStyle:{"color":"#772336"}},[_vm._v("•")]),_vm._v(" Confinamiento de residuos de manejo especial SEMA ")])]),_c('v-flex',{attrs:{"xs12":"","sm6":""}},[_c('v-img',{attrs:{"height":"450","contain":"","src":"/static/DSC_0199.png"}})],1)],1)],1),_c('br'),_c('v-container',{attrs:{"fluid":""}},[_c('v-layout',{staticStyle:{"margin-top":"0%","background-image":"url('/static/FondoContact1.png')","background-size":"cover"},attrs:{"row":"","wrap":"","justify-center":"","align-center":""}},[_c('v-flex',{staticClass:"contact",style:(_vm.mdUp ? 'text-align: left;' : 'text-align: center'),attrs:{"xs12":"","sm6":""}},[_c('v-card',{class:_vm.$vuetify.breakpoint.mdAndUp ? '' : 'contact-mobile',staticStyle:{"background":"transparent"},attrs:{"elevation":"0"}},[_c('v-container',[_c('v-layout',{attrs:{"row":"","justify-center":""}},[_c('div',{class:_vm.$vuetify.breakpoint.smAndUp
                    ? 'contact_form_wrapper'
                    : 'contact_form_wrapper_mobile'},[_c('v-layout',{attrs:{"row":"","justify-center":""}},[_c('h1',{staticStyle:{"color":"white","font-size":"70px"}},[_vm._v("Contáctanos")])]),_c('v-layout',{attrs:{"row":"","justify-center":""}},[_c('v-text-field',{attrs:{"rounded":"","height":"50","background-color":"white","placeholder":_vm.language == 0
                        ? 'Nombre y apellidos'
                        : 'Nombre y apellidos'},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1),_c('v-layout',{attrs:{"row":"","justify-center":""}},[_c('v-select',{attrs:{"rounded":"","height":"50","background-color":"white","item-value":"value","item-text":"text","items":_vm.sex_options,"placeholder":_vm.language == 0 ? 'Género' : 'Género'},model:{value:(_vm.gender),callback:function ($$v) {_vm.gender=$$v},expression:"gender"}})],1),_c('v-layout',{attrs:{"row":"","justify-center":""}},[_c('v-text-field',{attrs:{"rounded":"","height":"50","background-color":"white","placeholder":_vm.language == 0
                        ? 'Correo electrónico'
                        : 'Correo electrónico'},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1),_c('v-layout',{attrs:{"row":"","justify-center":""}},[_c('v-select',{attrs:{"rounded":"","height":"50","background-color":"white","items":_vm.estadosMX,"item-value":"nombre","item-text":"nombre","placeholder":_vm.language == 0
                        ? 'Estado de la república mexicana donde reside'
                        : 'Estado de la república mexicana donde reside'},model:{value:(_vm.state),callback:function ($$v) {_vm.state=$$v},expression:"state"}})],1),_c('v-layout',{attrs:{"row":"","justify-center":""}},[_c('v-text-field',{attrs:{"rounded":"","height":"50","background-color":"white","placeholder":_vm.language == 0
                        ? 'Número de teléfono'
                        : 'Número de teléfono'},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}})],1),_c('v-layout',{attrs:{"row":"","justify-center":""}},[_c('v-textarea',{staticStyle:{"resize":"none"},attrs:{"rounded":"","no-resize":"","height":"150","background-color":"white","placeholder":_vm.language == 0 ? 'Comentarios' : 'Comentarios'},model:{value:(_vm.comment),callback:function ($$v) {_vm.comment=$$v},expression:"comment"}})],1),_c('v-layout',{attrs:{"row":"","justify-center":""}},[_c('v-btn',{attrs:{"large":"","outlined":"","text":"","dark":"","color":"white"},on:{"click":_vm.submitForm}},[_vm._v(" Enviar ")])],1)],1)])],1),_c('v-container',[_c('v-flex',{attrs:{"xs12":""}},[_c('v-layout',{staticClass:"submit_form",attrs:{"row":"","justify-center":""}})],1)],1)],1)],1),(_vm.mdUp)?_c('v-flex',{attrs:{"xs12":"","sm6":""}},[_c('v-img',{attrs:{"height":"1000","src":"/static/DSC_0022.png"}})],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }